const bech32 = require('bech32');

const verifyCosmosAddress = (address) => {
  try {
    const result = bech32.decode(address.toLowerCase());
    return result.prefix == 'cosmos';
  }
  catch (e) {
    return false;
  }
};
const verifyIrisAddress = (address) => {
  try {
    const result = bech32.decode(address.toLowerCase());
    return result.prefix == 'iaa';
  }
  catch (e) {
    return false;
  }
};

export default {
  verifyCosmosAddress,
  verifyIrisAddress,
};
