export const compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const firstValue = a.platform.toUpperCase();
    const secondValue = b.platform.toUpperCase();

    let comparison = 0;
    if (firstValue > secondValue) {
        comparison = 1;
    } else if (firstValue < secondValue) {
        comparison = -1;
    }
    return comparison;
};
