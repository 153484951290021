<template>
  <div class="distribute-commission setting-wrap">
    <ValidationObserver ref="observer" @submit.prevent="onSave">
      <CForm>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol col="12">
                <CRow v-for="wallets in wallet" :key="wallets.id">
                  <CCol col="12">
                    <div class="pb-1 name">
                      {{wallets.platform}}
                      <label class="btn-switch">
                        <input type="checkbox" class="checkbox" v-model="wallets.enable_flg" />
                        <span class="check-silder"></span>
                      </label>
                    </div>
                    <CCard class="mb-2">
                      <CCardBody class="p-3">
                        <CRow>
                          <CCol col="12" sm="7">
                            <ValidationProvider
                              :name="$t('COLD_WALLET_PAGE_LABEL_ADDRESS')"
                              v-slot="{ errors }"
                            >
                              <CRow class="custom-input" alignVertical="center">
                                <CCol
                                  xl="2"
                                  class="content"
                                >{{$t("COLD_WALLET_PAGE_LABEL_ADDRESS")}}</CCol>
                                <CCol xl="10" class="content">
                                  <input
                                    class="input-field border-radius"
                                    :precision="0"
                                    v-model="wallets.reward_address"
                                    @change="checkAddress(wallets)"
                                  />
                                </CCol>
                                <CCol xl="2"></CCol>
                                <CCol xl="10" v-if="errors" class="error-msg">
                                  <span v-if="wallets.platform === 'ATOM'">{{errorAtom}}</span>
                                  <span v-if="wallets.platform === 'IRIS'">{{errorIris}}</span>
                                </CCol>
                              </CRow>
                            </ValidationProvider>
                          </CCol>
                          <CCol col="12" sm="5">
                            <ValidationProvider
                              rules="required|min_value:0"
                              :name="$t('COLD_WALLET_PAGE_FIELD_NAME_VALIDATE_MIN_AMOUNT')"
                              v-slot="{ errors }"
                            >
                              <CRow class="custom-input" alignVertical="center">
                                <CCol xl="2" class="content">{{$t("COLD_WALLET_PAGE_LABEL_MIN")}}</CCol>
                                <CCol xl="10" class="content">
                                  <currency-input
                                    class="input-field"
                                    :precision="3"
                                    v-model="wallets.min_amount"
                                  />
                                  <span class="unit">{{wallets.amount_unit}}</span>
                                </CCol>
                                <CCol xl="2"></CCol>
                                <CCol xl="10" v-if="errors" class="error-msg">{{errors[0]}}</CCol>
                              </CRow>
                            </ValidationProvider>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </CCol>
              <CCol col="12" class="mt-2 text-right">
                <CButton
                  class="pl-4 pr-4"
                  color="primary"
                  type="submit"
                >{{$t("COLD_WALLET_PAGE_BUTTON_SAVE")}}</CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CForm>
    </ValidationObserver>
    <SmallModalComponent
      @close-modal="onCloseModal"
      @verify-code="onVerify"
      :showModal="showModal"
      :title="modalTitle"
      :content="modalContent"
      :btnLabel="btnLabel"
      :action="modalAction"
      :isShowInput="isShowInput"
    />
  </div>
</template>
<script>
import SmallModalComponent from '@/components/SmallModalComponent';
import endpoints from '@/constants/endpoints';
import { compare } from '@/utils/lib';
import { mapState, mapActions } from 'vuex';
import verifyAddress from '@/utils/verifyAddress';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'ColdWallets',
  components: { SmallModalComponent },
  data() {
    return {
      wallet: [],
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715',
      },
      showModal: false,
      modalTitle: this.$t('COLD_WALLET_PAGE_MODAL_TITLE'),
      modalContent: this.$t('COLD_WALLET_PAGE_MODAL_CONTENT'),
      btnLabel: this.$t('COLD_WALLET_PAGE_MODAL_BUTTON_LABEL'),
      modalAction: this.$t('COLD_WALLET_PAGE_MODAL_ACTION'),
      errorAtom: '',
      errorIris: '',
      isShowInput: true,
    };
  },
  computed: {
    ...mapState({
      checked2fa: (state) => state.auth.twofaEnableFlag,
    }),
  },
  async mounted() {
    const [wallet] = await Promise.all([this.getWallet()]);
    if (!wallet) {
      return;
    }

    this.wallet = wallet.items.slice().sort(compare);
  },
  methods: {
    async getWallet() {
      try {
        const result = await this.$http.get(endpoints.getColdWallet);
        return result.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    async onSave() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid || this.errorAtom || this.errorIris) {
        return;
      } else {
        if (this.checked2fa) {
          this.showModal = true;
        } else {
          this.$router.push('/admin/profile');
        }
      }
    },
    async onVerify(value) {
      try {
        this.showModal = true;
        const items = this.wallet.map((el) => {
          return {
            id: el.id,
            reward_address: el.reward_address,
            min_amount: el.min_amount,
            enable_flg: el.enable_flg,
          };
        });
        const payload = {
          items,
          twofa_code: value,
        };
        const result = await this.$http.post(endpoints.updateColdWallet, payload);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('COLD_WALLET_PAGE_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('COLD_WALLET_PAGE_TEXT_NOTIFY_SUCCESS'),
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('COLD_WALLET_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
    checkAddress(value) {
      switch (value.platform) {
        case 'ATOM':
          this.errorAtom = verifyAddress.verifyCosmosAddress(value.reward_address)
            ? ''
            : this.$t('COLD_WALLET_PAGE_FIELD_NAME_VALIDATE_ATOM_ADDRESS');
          break;

        case 'IRIS':
          this.errorIris = verifyAddress.verifyIrisAddress(value.reward_address)
            ? ''
            : this.$t('COLD_WALLET_PAGE_FIELD_NAME_VALIDATE_IRIS_ADDRESS');
          break;

        default:
          this.errorAtom = '';
          this.errorIris = '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.name {
  margin-bottom: 15px;
  label {
    float: right;
  }
}
.distribute-commission {
  .form-group {
    margin-bottom: 0;
  }
  .form-control {
    text-align: right;
  }
  .custom-input {
    padding-top: 4px;
    .col-xl-3 {
      text-align: right;
      padding-right: 0;
      @media (max-width: 1200px) {
        text-align: left;
      }
    }
    .content {
      display: flex;
      align-items: center;
      .input-field {
        padding: 6px 12px;
        width: 90%;
        border: 1px solid #d8dbe0;
        border-radius: 0.25rem 0 0 0.25rem;
        text-align: right;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .unit {
        margin: 0 auto;
        background: #f0f3f5;
        padding: 6px 0;
        text-align: center;
        border: 1px solid #e4e7ea;
        border-radius: 0 0.25rem 0.25rem 0;
        width: 90px;
      }
    }
  }
  .error-msg {
    color: red;
    font-size: 14px;
    line-height: 16px;
    padding-top: 5px;
    padding-left: 15px;
  }
  .card-body {
    .card {
      margin-bottom: 15px !important;
    }
  }
  .btn-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    .checkbox {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
      &:checked + .check-silder::before {
        border-color: #009900;
        transform: translateX(16px);
      }
      &:checked + .check-silder {
        background: #009900;
      }
    }
    .check-silder {
      position: absolute;
      cursor: pointer;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: #fff;
      border: 1px solid #dadada;
      transition: 0.4s;
      border-radius: 24px;
      &:before {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 1px;
        bottom: 1px;
        top: 1px;
        background: #fff;
        border: 1px solid #dadada;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  }
}
</style>
